import React from "react"
import { Container, Layout, SEO, Grid, TextRow, ImageRow, ExternalLink, Tag } from "../../components";

import example from '../../images/projects/messenger.png';

const Messenger = () => (
  <Layout hideProfile>
    <SEO title="Messenger: Instant Messaging Service" />
    <Container>
      <Grid>
        <TextRow>
          <h1>Messenger: Instant Messaging Service</h1>

          <Tag>AngularJS</Tag>
          <Tag>Node</Tag>
          <Tag>Gulp</Tag>
          <Tag>Web Socket</Tag>
          <Tag>Saas</Tag>
          <Tag>Babel</Tag>

          <p>
            <strong>Sexlog</strong> is the largest social network adult content in Brazil.
            For being a social network, we had a lot of challenges related to usability and feature to connect our users.
          </p>
          <p>
            The Messenger project was created based on the strategy to keep our users inside of the platform and allow them to
            have a security instantly communication.
          </p>

          <p>
            The project was build using <strong>AngularJS, SAAS and WebSocket</strong>.
            I performed as one of <strong>Front-end engineers</strong>.
          </p>
          <p>
            Click on the link below and create an account to see the result.
          </p>

          <ExternalLink href="https://sexlog.com" target="_blank" rel="noopener noreferrer">Sexlog</ExternalLink>

        </TextRow>

        <ImageRow>
          <img alt="Messenger example" src={example} />
        </ImageRow>

      </Grid>
    </Container>
  </Layout>
);

export default Messenger;
